@import url("https://fonts.googleapis.com/css?family=Ovo|Karla");

// variables
$background-color: rgba(0, 0, 0, 0.95);
$body-font-color: #fcdcbf;
$body-font-family: Karla, sans-serif;
$body-font-size: 16px;
$border-color: #fcdcbf;
$container-background-color: #222222;
$darken-background-color: rgba(0, 0, 0, 0.05);
$h1-font-size: 48px;
$h2-font-size: 32px;
$header-font-color: #ffffff;
$header-font-family: Ovo, serif;
$spacing: 12px;
$media-phone-max-width: 320px;

// keyframes
@keyframes fade-in {
  0% {
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// dom styles
body {
  * {
    color: $body-font-color;
    font-family: $body-font-family;
    font-size: $body-font-size;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    @media only screen and (max-width: $media-phone-max-width) {
      font-size: $body-font-size * 0.75;
    }
  }

  background: $background-color;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100vw;

  a {
    cursor: pointer;
    font-weight: bold;
    pointer-events: auto;
    &:hover {
      color: lighten($body-font-color, 10%);
    }
  }

  p {
    margin-block-end: 3em;
    margin-block-start: 1em;
  }

  h1,
  h2 {
    color: $header-font-color;
    font-family: $header-font-family;
    margin: $spacing 0;
  }
  h1 {
    font-size: $h1-font-size;
    @media only screen and (max-width: $media-phone-max-width) {
      font-size: $h1-font-size * 0.75;
    }
  }
  h2 {
    font-size: $h2-font-size;
    @media only screen and (max-width: $media-phone-max-width) {
      font-size: $h2-font-size * 0.5;
    }
  }
}

.button {
  background-color: $container-background-color;
  border: 1px solid $border-color;
  border-radius: $spacing * 2;
  color: $body-font-color;
  cursor: pointer;
  padding: $spacing $spacing * 2;
  pointer-events: auto;
  &:hover {
    background-color: lighten($container-background-color, 10%);
  }
}

// shared styles
.fixed {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
}

.nudge-right {
  margin-right: 24px;
}

// app styles
.about {
  @extend .fixed;
  background: $background-color;
  box-sizing: border-box;
  overflow: auto;
  padding: $spacing * 4 $spacing * 2;
  pointer-events: auto;

  .about-content {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
  }
}

.content {
  max-width: 30vw;
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translate(0%, -50%);
  @media only screen and (max-width: $media-phone-max-width) {
    max-width: 60vw;
    right: $spacing * 2;
  }
}

.cover {
  @extend .fixed;
  background-color: $background-color;
}

.details {
  @extend .fixed;
  background: $darken-background-color;
  padding: $spacing;

  .details-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: $spacing * 2 0;
  }
}

.footer {
  @extend .fixed;
  bottom: $spacing;
  left: auto;
  right: $spacing;
  top: auto;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $spacing $spacing * 3;
  @media only screen and (max-width: $media-phone-max-width) {
    padding: $spacing;
  }
}

.hidden {
  opacity: 0;
}

.intro {
  @extend .fixed;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.overlay {
  @extend .fixed;
  .overlay-subtitle {    
    @media only screen and (max-width: $media-phone-max-width) {
      display: none;
    }
  }
}